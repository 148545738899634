<template>
  <v-container fluid>
    <div class="d-flex mb-6 align-center" style="gap: 20px">
      <v-spacer />
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="mx-auto"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <h1 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="width: 300px;font-size: xx-large;border-radius: 5px;">
            {{ date ? new Date(date).toLocaleDateString('esp',{ weekday :'short', day:'numeric',month:'short', year:'numeric' }).toLocaleUpperCase() : "CARGANDO..." }}
          </h1>
        </template>
        <v-date-picker
          v-model="dateAux"
          :first-day-of-week="1"
          no-title
          scrollable
          event-color="error"
          @click:date="menu = false;"
        ></v-date-picker>
      </v-menu>
      <v-spacer />
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb" :to="`/planificacion/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-notebook-multiple</v-icon>
      </v-btn>                
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat" :to="`/check_catering/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-truck</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['CONTROL_HORAS']))" :to="`/horas/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-human-male</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && calc && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['RESUMEN_RENTABILIDAD']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS']))" :to="`/costes/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-alarm-panel</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn @click.stop="savePlan" :loading="saving" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
    <div>
      <v-card :class="active2 ? 'active2' : 'activent2'" :style="'overflow:' + (!active2 ? 'hidden;' : 'auto;')">
        <v-card-title style="max-width:500px;">
          NECESIDADES FINDE
        <v-spacer />
        <v-btn fab x-small class="me-2" color="info" @click="imprimir(false)">
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn fab x-small class="ms-2" color="error" @click="imprimir(true)">
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="primary" @click="active2 = !active2" class="mx-5" style="justify-self: end;" x-small><v-icon>{{ active2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <div :style="'overflow:' + (!active2 ? 'hidden;' : 'auto;') + 'height:700px;'">
            <table style="width: min-content;border-collapse: collapse;">
              <thead>
                <tr>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important"><h2>MATERIAL</h2></th>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important"><h2>TOTAL NECESIDADES</h2></th>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important"><h2>INVENTARIO</h2></th>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important"><h2>STOCK PTE</h2></th>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important" v-for="esp in espacios" :key="esp.idMaterial">{{ esp.espacio }} ({{ esp.nInv }})</th>
                  <th class="px-2" style="z-index: 1000; box-shadow: inset 0 -1px 0 var(--v-primary-base);text-align: left; vertical-align: middle;position: sticky !important; top: 0 !important"><h2>OBSERVACIONES</h2></th>
                </tr>
              </thead>
              <tbody :style="'overflow:' + (!active2 ? 'hidden;' : 'auto;') + 'height:700px;'">
                <h2 class="primary--text ms-3 my-3">SILLAS</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'SILLAS').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.stijera * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.stijera * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.stijera * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.stijera * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.stijera }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.scrossback * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.scrossback * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.scrossback * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.scrossback * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.scrossback }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.shotel * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.shotel * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.shotel * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.shotel * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.shotel }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.splastico * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.splastico * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.splastico * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.splastico * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.splastico }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.sbambu * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.sbambu * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.sbambu * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.sbambu * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.sbambu }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cojinestijera * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cojinestijera * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cojinestijera * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cojinestijera * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cojinestijera }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.fundassillasplastico * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fundassillasplastico * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fundassillasplastico * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fundassillasplastico * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.fundassillasplastico }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">BUFFETS & PLANCHAS</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'BUFFETS & PLANCHAS').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.buffetSoporte * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.buffetSoporte * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.buffetSoporte * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.buffetSoporte * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.buffetSoporte }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.paptvo * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.paptvo * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.paptvo * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.paptvo * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.paptvo }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">MESAS</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'MESAS').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.veladoresplegables * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresplegables * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresplegables * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresplegables * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.veladoresplegables }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.veladoresblancos * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresblancos * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresblancos * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.veladoresblancos * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.veladoresblancos }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mesas12 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas12 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas12 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas12 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mesas12 }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mesas18 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas18 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas18 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mesas18 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mesas18 }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.aspirinasgrandes * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinasgrandes * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinasgrandes * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinasgrandes * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.aspirinasgrandes }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.tableros2m * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tableros2m * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tableros2m * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tableros2m * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.tableros2m }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.tablerospartidos * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tablerospartidos * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tablerospartidos * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tablerospartidos * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ (parseInt(espacio.tablerospartidos) || 0) }}</td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.aspirinaextraxxl * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinaextraxxl * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinaextraxxl * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.aspirinaextraxxl * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ (parseInt(espacio.aspirinaextraxxl) || 0) }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">CRISTAL</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'CRISTAL').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copacava * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacava * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacava * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacava * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copacava }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copavino * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavino * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavino * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavino * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copavino }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copaagualila * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaagualila * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaagualila * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaagualila * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copaagualila }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.vasoaguaambar * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguaambar * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguaambar * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguaambar * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.vasoaguaambar }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.vasoaguatrans * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguatrans * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguatrans * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasoaguatrans * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.vasoaguatrans }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copavinosalon * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavinosalon * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavinosalon * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copavinosalon * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copavinosalon }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copacavasalon * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacavasalon * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacavasalon * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copacavasalon * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copacavasalon }}</td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cbff * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cbff * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cbff * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cbff * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cbff }}</td>
                    </template>
                    <template v-if="i == 8">
                        <td><h3 style="text-align:center">{{ Math.abs(pte.copaBalon70 * 1) }}</h3></td>
                        <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                        <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaBalon70 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaBalon70 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaBalon70 * 1) }}</h3></td>
                        <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copaBalon70 }}</td>
                    </template>
                    <template v-if="i == 9">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.copaTubo * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaTubo * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaTubo * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.copaTubo * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.copaTubo }}</td>
                    </template>
                    <template v-if="i == 10">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.vasosidra * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasosidra * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasosidra * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.vasosidra * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.vasosidra }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">MENAJE</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'MENAJE').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bp1 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp1 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp1 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp1 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bp1 }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bp2 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp2 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp2 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp2 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bp2 }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bp3 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp3 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp3 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bp3 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bp3 }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.ppc1 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc1 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc1 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc1 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.ppc1 }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.ppc2 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc2 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc2 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ppc2 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.ppc2 }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bas * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bas * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bas * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bas * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bas }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bls * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bls * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bls * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bls * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bls }}</td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.bolNose * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bolNose * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bolNose * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.bolNose * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.bolNose }}</td>
                    </template>
                    <template v-if="i == 8">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.ph * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ph * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ph * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.ph * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.ph }}</td>
                    </template>
                    <template v-if="i == 9">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.plp * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plp * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plp * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plp * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.plp }}</td>
                    </template>
                    <template v-if="i == 10">
                        <td><h3 style="text-align:center">{{ Math.abs(pte.plcv * 1) }}</h3></td>
                        <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                        <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plcv * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plcv * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.plcv * 1) }}</h3></td>
                        <td style="text-align:center" v-for="espacio in espacios">{{ espacio.plcv }}</td>
                    </template>
                    <template v-if="i == 11">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.pla * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pla * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pla * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pla * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.pla }}</td>
                    </template>
                    <template v-if="i == 12">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.pca * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pca * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pca * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pca * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.pca }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">SERVICIO CAFÉ</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'SERVICIO CAFÉ').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cc * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cc * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cc * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cc * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cc }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.jc * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.jc * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.jc * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.jc * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.jc }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
                
                <h2 class="primary--text ms-3 my-3">LENCERÍA</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'LENCERÍA').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.serv * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.serv * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.serv * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.serv * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.serv }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mmgrnd * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mmgrnd * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mmgrnd * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mmgrnd * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mmgrnd }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mllnn * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mllnn * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mllnn * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mllnn * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mllnn }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mbrgrnd * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrgrnd * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrgrnd * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrgrnd * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mbrgrnd }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mgrlgrnd * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgrlgrnd * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgrlgrnd * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgrlgrnd * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mgrlgrnd }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mgjspgrnd * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjspgrnd * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjspgrnd * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjspgrnd * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mgjspgrnd }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mcomb * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcomb * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcomb * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcomb * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mcomb }}</td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mbrpq * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrpq * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrpq * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrpq * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mbrpq }}</td>
                    </template>
                    <template v-if="i == 8">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mbrllnn * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrllnn * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrllnn * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mbrllnn * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mbrllnn }}</td>
                    </template>
                    <template v-if="i == 9">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mcallnnp * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcallnnp * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcallnnp * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mcallnnp * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mcallnnp }}</td>
                    </template>
                    <template v-if="i == 10">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.mgjsp * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjsp * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjsp * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.mgjsp * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.mgjsp }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
                
                <h2 class="primary--text ms-3 my-3">CUBIERTOS</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'CUBIERTOS').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cuchillos * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cuchillos * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cuchillos * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cuchillos * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cuchillos }}</td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.tenedores * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tenedores * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tenedores * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tenedores * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.tenedores }}</td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cucharas * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cucharas * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cucharas * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cucharas * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cucharas }}</td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.tTrinchero * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tTrinchero * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tTrinchero * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tTrinchero * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.tTrinchero }}</td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.tAncho * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tAncho * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tAncho * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.tAncho * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.tAncho }}</td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cTrinchero * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cTrinchero * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cTrinchero * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cTrinchero * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cTrinchero }}</td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cCarne * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cCarne * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cCarne * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cCarne * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cCarne }}</td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.pPescado * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pPescado * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pPescado * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.pPescado * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.pPescado }}</td>
                    </template>
                    <template v-if="i == 8">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cPiscis * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cPiscis * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cPiscis * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cPiscis * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cPiscis }}</td>
                    </template>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                  </tr>
                </template>
  
                <h2 class="primary--text ms-3 my-3">COMPLEMENTOS GENERALES</h2>
                <template v-for="(m ,i) in stock.filter(s => s.familia == 'COMPLEMENTOS GENERALES').sort((a,b) => {
                    var keyA = a.orden;
                    var keyB = b.orden;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0
                  })">
                  <tr>
                    <td style="white-space:nowrap;"><h3 class="ms-2">{{ m.nombre }}</h3></td>
                    <template v-if="i == 0">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.hornoGas * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.hornoGas * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.hornoGas * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.hornoGas * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.hornoGas }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 1">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.horno380 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.horno380 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.horno380 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.horno380 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.horno380 }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 2">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cafeteraMolinillo * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cafeteraMolinillo * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cafeteraMolinillo * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cafeteraMolinillo * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cafeteraMolinillo }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 3">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.arcones * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.arcones * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.arcones * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.arcones * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.arcones }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 4">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.fuegoTierra * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fuegoTierra * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fuegoTierra * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.fuegoTierra * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.fuegoTierra }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 5">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.alargadores * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.alargadores * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.alargadores * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.alargadores * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.alargadores }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 6">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.petaca * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.petaca * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.petaca * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.petaca * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.petaca }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 7">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.cable380 * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cable380 * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cable380 * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.cable380 * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.cable380 }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                    <template v-if="i == 8">
                      <td><h3 style="text-align:center">{{ Math.abs(pte.focoLuz * 1) }}</h3></td>
                      <td><h3 style="text-align:center">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h3></td>
                      <td :style="'text-align:center;'+((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.focoLuz * 1)) < 0 ? 'color:var(--v-error-base)' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.focoLuz * 1)) == 0 ? 'color:var(--v-warning-base)' : '')"><h3>{{  (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (pte.focoLuz * 1) }}</h3></td>
                      <td style="text-align:center" v-for="espacio in espacios">{{ espacio.focoLuz }}</td>
                    <td>
                      <v-text-field v-if="!loadingObs" hide-details dense filled v-model="observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones"></v-text-field>
                    </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="d-flex flex-wrap my-6" style="gap: 20px">
      <NecesidadesHueco
        @saved="getHuecos"
        v-for="(h, i) in huecos"
        :key="i"
        :hueco="h"
        :auxTi="100*i"
        v-model="huecos[i]"
        :date="dateParsed"
        @click="h.active = !h.active"
      />
    </div>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
export default {
  props: {
    date: String,
  },
  components: {
    NecesidadesHueco: () => import("../components/NecesidadesHueco.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  watch:{
    dateAux:{
      async handler(v) {
        const confirmation = await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Quieres guardar los cambios antes de irte?",
          { color: "primary" }
        );
        if (confirmation) {
          await this.savePlan(true);
        }
        this.$set(this.$data, "dateAux", this.dateAux)
        this.$router.push({path : "/necesidades/" + this.dateAux}).catch(() => {})
      },
    }
  },
  data() {
    return {
      active2: false,
      loadingObs: true,
      refreshKey: 0,
      ordenTipos: ["Boda", "Comunión", "Corporativo", "Evento", "Pruebas boda"],
      ordenEspacios: ["PISCIS", "MASMONZON", "LALANNE", "LAUS", "CATERING"],
      ordenSalones: ["ROSA", "ROYAL", "CONTINENTAL", "EL JARDIN", "LA CUCHARILLA", "MULTIUSOS", "PISTAS", "CLASH", "Blanco", "Antiguo", "LAGO", "LOS VIÑEDOS"],
      ordenHorarios: ["MAÑANA", "MAÑANA Y COMIDA", "TODO EL DÍA", "COMER", "COMIDA Y CENA", "TARDE", "TARDE Y CENA", "CENA", "PENDIENTE"],
      huecos: [],
      equipo: [],
      saving: false,
      menu: false,
      loading: false,
      filterCategories: {},
			autosave: null,
      stock: [],
      observaciones: [],
      dateAux: new Date(this.date + ' 12:00:00').toISOString().split('T')[0]
    };
  },
  computed: {
    espacios(){
      var res = []
      var keys = []
      this.huecos.forEach(h => {
        if (!keys.includes(h.espacio)) {
          if (h.espacio == "CATERING") keys.push(h.nombreSalon)
          else keys.push(h.espacio)
        }
      });
      keys.forEach(espacio => {
        res.push({espacio : espacio,nInv:0,stijera:0,scrossback:0,shotel:0,splastico:0,sbambu:0,cojinestijera:0,fundassillasplastico:0,buffetSoporte:0,paptvo:0,veladoresplegables:0,veladoresblancos:0,mesas12:0,mesas18:0,aspirinasgrandes:0,tableros2m:0,tablerospartidos:0,aspirinaextraxxl:0,copacava:0,copavino:0,copaagualila:0,vasoaguaambar:0,vasoaguatrans:0,copavinosalon:0,copacavasalon:0,cbff:0,vasosidra:0,copaBalon70:0,copaTubo:0,bp1:0,bp2:0,bp3:0,ppc1:0,ppc2:0,bas:0,pla:0,pca:0,bls:0,bolNose:0,plcv:0,serv:0,mmgrnd:0,mllnn:0,mbrgrnd:0,mgrlgrnd:0,mgjspgrnd:0,mcomb:0,mbrpq:0,mbrllnn:0,mcallnnp:0,mgjsp:0,ph:0,plp:0,cc:0,jc:0,cuchillos:0,tenedores:0,cucharas:0,tTrinchero:0,tAncho:0,cTrinchero:0,cCarne:0,pPescado:0,cPiscis:0,hornoGas:0,horno380:0,fuegoTierra:0,cafeteraMolinillo:0,arcones:0,alargadores:0,petaca:0,cable380:0,focoLuz:0})
        this.huecos.filter(h => h.espacio == espacio || h.nombreSalon == espacio).forEach(hueco => {
          res.filter(r => r.espacio == espacio)[0].nInv += parseInt(hueco.nAdultos) + parseInt(hueco.nNinos) + parseInt((hueco.datos['tec'] || 0));
          res.filter(r => r.espacio == espacio)[0].stijera += ((parseInt(hueco.datos['SILLAS TIJERA*EC']) || 0) + (parseInt(hueco.datos['SILLAS TIJERA*APTVO']) || 0) + (parseInt(hueco.datos['SILLAS TIJERA*SALON']) || 0) + (parseInt(hueco.datos['SILLAS TIJERA*FF']) || 0))
          res.filter(r => r.espacio == espacio)[0].scrossback += ((parseInt(hueco.datos['SILLAS CROSSBACK*EC']) || 0) + (parseInt(hueco.datos['SILLAS CROSSBACK*APTVO']) || 0) + (parseInt(hueco.datos['SILLAS CROSSBACK*SALON']) || 0) + (parseInt(hueco.datos['SILLAS CROSSBACK*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].shotel += ((parseInt(hueco.datos['SILLAS HOTEL*EC']) || 0) + (parseInt(hueco.datos['SILLAS HOTEL*APTVO']) || 0) + (parseInt(hueco.datos['SILLAS HOTEL*SALON']) || 0) + (parseInt(hueco.datos['SILLAS HOTEL*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].splastico += ((parseInt(hueco.datos['SILLAS PLASTICO*EC']) || 0) + (parseInt(hueco.datos['SILLAS PLASTICO*APTVO']) || 0) + (parseInt(hueco.datos['SILLAS PLASTICO*SALON']) || 0) + (parseInt(hueco.datos['SILLAS PLASTICO*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].sbambu += ((parseInt(hueco.datos['SILLAS BAMBU*EC']) || 0) + (parseInt(hueco.datos['SILLAS BAMBU*APTVO']) || 0) + (parseInt(hueco.datos['SILLAS BAMBU*SALON']) || 0) + (parseInt(hueco.datos['SILLAS BAMBU*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cojinestijera += ((parseInt(hueco.datos['COJINES SILLAS TIJERA*EC']) || 0) + (parseInt(hueco.datos['COJINES SILLAS TIJERA*APTVO']) || 0) + (parseInt(hueco.datos['COJINES SILLAS TIJERA*SALON']) || 0) + (parseInt(hueco.datos['COJINES SILLAS TIJERA*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].fundassillasplastico += ((parseInt(hueco.datos['FUNDAS SILLAS PLASTICO*EC']) || 0) + (parseInt(hueco.datos['FUNDAS SILLAS PLASTICO*APTVO']) || 0) + (parseInt(hueco.datos['FUNDAS SILLAS PLASTICO*SALON']) || 0) + (parseInt(hueco.datos['FUNDAS SILLAS PLASTICO*FF']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].buffetSoporte += ((parseInt(hueco.datos['buffetSoporte']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].paptvo += ((parseInt(hueco.datos['paptvo']) || 0) || 0)
          console.log(hueco.datos['VELADORES PLEGABLES*APTVO']);
          console.log(hueco.datos['VELADORES PLEGABLES*SALON'])
          console.log(hueco.datos['VELADORES PLEGABLES*CPLG'])
          console.log(hueco.datos['VELADORES PLEGABLES*GNRL'])
          res.filter(r => r.espacio == espacio)[0].veladoresplegables += ((parseInt(hueco.datos['VELADORES PLEGABLES*APTVO']) || 0) + (parseInt(hueco.datos['VELADORES PLEGABLES*SALON']) || 0) + (parseInt(hueco.datos['VELADORES PLEGABLES*CPLG']) || 0) + (parseInt(hueco.datos['VELADORES PLEGABLES*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].veladoresblancos += ((parseInt(hueco.datos['VELADORES BLANCOS*APTVO']) || 0) + (parseInt(hueco.datos['VELADORES BLANCOS*SALON']) || 0) + (parseInt(hueco.datos['VELADORES BLANCOS*CPLG']) || 0) + (parseInt(hueco.datos['VELADORES BLANCOS*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mesas12 += ((parseInt(hueco.datos['MESAS 1,2 REDONDAS*APTVO']) || 0) + (parseInt(hueco.datos['MESAS 1,2 REDONDAS*SALON']) || 0) + (parseInt(hueco.datos['MESAS 1,2 REDONDAS*CPLG']) || 0) + (parseInt(hueco.datos['MESAS 1,2 REDONDAS*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mesas18 += ((parseInt(hueco.datos['MESAS 1,8 REDONDAS*APTVO']) || 0) + (parseInt(hueco.datos['MESAS 1,8 REDONDAS*SALON']) || 0) + (parseInt(hueco.datos['MESAS 1,8 REDONDAS*CPLG']) || 0) + (parseInt(hueco.datos['MESAS 1,8 REDONDAS*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].aspirinasgrandes += ((parseInt(hueco.datos['ASPIRINAS GRANDES*APTVO']) || 0) + (parseInt(hueco.datos['ASPIRINAS GRANDES*SALON']) || 0) + (parseInt(hueco.datos['ASPIRINAS GRANDES*CPLG']) || 0) + (parseInt(hueco.datos['ASPIRINAS GRANDES*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].tableros2m += ((parseInt(hueco.datos['TABLEROS 2m*APTVO']) || 0) + (parseInt(hueco.datos['TABLEROS 2m*SALON']) || 0) + (parseInt(hueco.datos['TABLEROS 2m*CPLG']) || 0) + (parseInt(hueco.datos['TABLEROS 2m*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].tablerospartidos += ((parseInt(hueco.datos['TABLEROS PARTIDOS 1,8m*APTVO']) || 0) + (parseInt(hueco.datos['TABLEROS PARTIDOS 1,8m*SALON']) || 0) + (parseInt(hueco.datos['TABLEROS PARTIDOS 1,8m*CPLG']) || 0) + (parseInt(hueco.datos['TABLEROS PARTIDOS 1,8m*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].aspirinaextraxxl += ((parseInt(hueco.datos['ASPIRINA EXTRA XXL*APTVO']) || 0) + (parseInt(hueco.datos['ASPIRINA EXTRA XXL*SALON']) || 0) + (parseInt(hueco.datos['ASPIRINA EXTRA XXL*CPLG']) || 0) + (parseInt(hueco.datos['ASPIRINA EXTRA XXL*GNRL']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copacava += ((parseInt(hueco.datos['copacava']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copavino += ((parseInt(hueco.datos['copavino']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copaagualila += ((parseInt(hueco.datos['vasoagualila']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].vasoaguaambar += ((parseInt(hueco.datos['vasoaguaambar']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].vasoaguatrans += ((parseInt(hueco.datos['vasoaguatrans']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copavinosalon += ((parseInt(hueco.datos['copavinosalon']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copacavasalon += ((parseInt(hueco.datos['copacavasalon']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cbff += ((parseInt(hueco.datos['cbff']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].vasosidra += ((parseInt(hueco.datos['vasosidra']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copaBalon70 += ((parseInt(hueco.datos['copaBalon70']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].copaTubo += ((parseInt(hueco.datos['copaTubo']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bp1 += ((parseInt(hueco.datos['bp1']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bp2 += ((parseInt(hueco.datos['bp2']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bp3 += ((parseInt(hueco.datos['bp3']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].ppc1 += ((parseInt(hueco.datos['ppc1']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].ppc2 += ((parseInt(hueco.datos['ppc2']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bas += ((parseInt(hueco.datos['bas']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].pla += ((parseInt(hueco.datos['pla']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].pca += ((parseInt(hueco.datos['pca']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bls += ((parseInt(hueco.datos['bls']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].bolNose += ((parseInt(hueco.datos['bolNose']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].plcv += ((parseInt(hueco.datos['plcv']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].serv += ((parseInt(hueco.datos['serv']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mmgrnd += ((parseInt(hueco.datos['mmgrnd']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mllnn += ((parseInt(hueco.datos['mllnn']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mbrgrnd += ((parseInt(hueco.datos['mbrgrnd']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mgrlgrnd += ((parseInt(hueco.datos['mgrlgrnd']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mgjspgrnd += ((parseInt(hueco.datos['mgjspgrnd']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mcomb += ((parseInt(hueco.datos['mcomb']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mbrpq += ((parseInt(hueco.datos['mbrpq']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mbrllnn += ((parseInt(hueco.datos['mbrllnn']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mcallnnp += ((parseInt(hueco.datos['mcallnnp']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].mgjsp += ((parseInt(hueco.datos['mgjsp']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].ph += ((parseInt(hueco.datos['ph']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].plp += ((parseInt(hueco.datos['plp']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cc += ((parseInt(hueco.datos['cc']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].jc += ((parseInt(hueco.datos['jc']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cuchillos += ((parseInt(hueco.datos['CUCHILLO EVENTOS*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].tenedores += ((parseInt(hueco.datos['TENEDOR EVENTOS*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cucharas += ((parseInt(hueco.datos['CUCHARA EVENTOS*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].tTrinchero += ((parseInt(hueco.datos['TENEDOR TRINCHERO (PISCIS)*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].tAncho += ((parseInt(hueco.datos['TENEDOR ANCHO*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cTrinchero += ((parseInt(hueco.datos['CUCHILLO TRINCHERO (PISCIS)*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cCarne += ((parseInt(hueco.datos['CUCHILLO CARNE (PISCIS)*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].pPescado += ((parseInt(hueco.datos['PALA PESCADO (PISCIS)*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cPiscis += ((parseInt(hueco.datos['CUCHARA PISCIS*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].hornoGas += ((parseInt(hueco.datos['HORNO GAS*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].horno380 += ((parseInt(hueco.datos['HORNO 380*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].fuegoTierra += ((parseInt(hueco.datos['FUEGO A TIERRA*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cafeteraMolinillo += ((parseInt(hueco.datos['CAFETERA y MOLINILLO (detallar)*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].arcones += ((parseInt(hueco.datos['ARCONES*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].alargadores += ((parseInt(hueco.datos['ALARGADORES*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].petaca += ((parseInt(hueco.datos['PETACA*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].cable380 += ((parseInt(hueco.datos['CABLE 380*']) || 0) || 0)
          res.filter(r => r.espacio == espacio)[0].focoLuz = ((parseInt(hueco.datos['FOCO LUZ*']) || 0) || 0)
        });
      });

      return res
    },
    pte(){
      var res = {nInv:0,stijera:0,scrossback:0,shotel:0,splastico:0,sbambu:0,cojinestijera:0,fundassillasplastico:0,buffetSoporte:0,paptvo:0,veladoresplegables:0,veladoresblancos:0,mesas12:0,mesas18:0,aspirinasgrandes:0,tableros2m:0,tablerospartidos:0,aspirinaextraxxl:0,copacava:0,copavino:0,copaagualila:0,vasoaguaambar:0,vasoaguatrans:0,copavinosalon:0,copacavasalon:0,cbff:0,vasosidra:0,copaBalon70:0,copaTubo:0,bp1:0,bp2:0,bp3:0,ppc1:0,ppc2:0,bas:0,pla:0,pca:0,bls:0,bolNose:0,plcv:0,serv:0,mmgrnd:0,mllnn:0,mbrgrnd:0,mgrlgrnd:0,mgjspgrnd:0,mcomb:0,mbrpq:0,mbrllnn:0,mcallnnp:0,mgjsp:0,ph:0,plp:0,cc:0,jc:0,cuchillos:0,tenedores:0,cucharas:0,tTrinchero:0,tAncho:0,cTrinchero:0,cCarne:0,pPescado:0,cPiscis:0,hornoGas:0,horno380:0,fuegoTierra:0,cafeteraMolinillo:0,arcones:0,alargadores:0,petaca:0,cable380:0,focoLuz:0,}
      this.espacios.forEach(e => {
        res.nInv -= e.nInv
        res.stijera -= e.stijera
        res.scrossback -= e.scrossback
        res.sbambu -= e.sbambu
        res.shotel -= e.shotel
        res.splastico -= e.splastico
        res.cojinestijera -= e.cojinestijera
        res.fundassillasplastico -= e.fundassillasplastico
        res.buffetSoporte -= e.buffetSoporte
        res.paptvo -= e.paptvo
        res.veladoresplegables -= e.veladoresplegables
        res.veladoresblancos -= e.veladoresblancos
        res.mesas12 -= e.mesas12
        res.mesas18 -= e.mesas18
        res.aspirinasgrandes -= e.aspirinasgrandes
        res.tableros2m -= e.tableros2m
        res.tablerospartidos -= e.tablerospartidos
        res.aspirinaextraxxl -= e.aspirinaextraxxl
        res.copacava -= e.copacava
        res.copavino -= e.copavino
        res.copaagualila -= e.copaagualila
        res.vasoaguaambar -= e.vasoaguaambar
        res.vasoaguatrans -= e.vasoaguatrans
        res.copavinosalon -= e.copavinosalon
        res.copacavasalon -= e.copacavasalon
        res.cbff -= e.cbff
        res.vasosidra -= e.vasosidra
        res.copaBalon70 -= e.copaBalon70
        res.copaTubo -= e.copaTubo
        res.bp1 -= e.bp1
        res.bp2 -= e.bp2
        res.bp3 -= e.bp3
        res.ppc1 -= e.ppc1
        res.ppc2 -= e.ppc2
        res.bas -= e.bas
        res.pla -= e.pla
        res.pca -= e.pca
        res.bls -= e.bls
        res.bolNose -= e.bolNose
        res.plcv -= e.plcv
        res.serv -= e.serv
        res.mmgrnd -= e.mmgrnd
        res.mllnn -= e.mllnn
        res.mbrgrnd -= e.mbrgrnd
        res.mgrlgrnd -= e.mgrlgrnd
        res.mgjspgrnd -= e.mgjspgrnd
        res.mcomb -= e.mcomb
        res.mbrpq -= e.mbrpq
        res.mbrllnn -= e.mbrllnn
        res.mcallnnp -= e.mcallnnp
        res.mgjsp -= e.mgjsp
        res.ph -= e.ph
        res.plp -= e.plp
        res.cc -= e.cc
        res.jc -= e.jc
        res.cuchillos -= e.cuchillos
        res.tenedores -= e.tenedores
        res.cucharas -= e.cucharas
        res.tTrinchero -= e.tTrinchero
        res.tAncho -= e.tAncho
        res.cTrinchero -= e.cTrinchero
        res.cCarne -= e.cCarne
        res.pPescado -= e.pPescado
        res.cPiscis -= e.cPiscis
        res.hornoGas -= e.hornoGas
        res.horno380 -= e.horno380
        res.fuegoTierra -= e.fuegoTierra
        res.cafeteraMolinillo -= e.cafeteraMolinillo
        res.arcones -= e.arcones
        res.alargadores -= e.alargadores
        res.petaca -= e.petaca
        res.cable380 -= e.cable380
        res.focoLuz -= e.focoLuz
      });
      return res
    },
    dateParsed() {
      return new Date(this.date).toLocaleDateString();
    },
    prevDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() - 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
    nextDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() + 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
  },
  methods: {
    async getStock(){
      const { data } = await axios({
        url: `/necesidades/stock/linked`,
      });
      this.stock = data
    },
    async getObservaciones(){
      const { data } = await axios({
        url: `/necesidades/observaciones/${this.date}`,
      });
      this.observaciones = data
      this.stock.forEach(mat => {
        if (this.observaciones.filter(obs => obs.idMaterial == mat.idMaterial).length < 1) this.observaciones.push({idMaterial : mat.idMaterial, observaciones : null, date: this.date});
      });
      this.loadingObs = false;
    },
    async savePlan(skip){
      const confirmation =
        skip ||
        (await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Estás seguro de que quieres guardar los cambios?",
          { color: "primary" }
        ));
      if (!confirmation) return;

      const details = this.huecos.reduce((r, c) => {
        r[c.custom_key] ??= { datos: c.datos };
        return r;
      }, Object.create(Object.prototype));
      try {
        this.saving = true;
        await Promise.all([
          axios({
            method: "post",
            url: `/necesidades/${this.date}`,
            data: details,
          }),
          axios({
            method: "post",
            url: `/necesidades/observaciones/${this.date}`,
            data: this.observaciones,
          }),
        ]);
        this.saving = false;

        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
      } catch (err) {
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    async getHuecos() {
      const { data } = await axios({
        url: `/necesidades/${this.date}`,
      });

      this.huecos = data;
      this.huecos.forEach(element => {
        element.usados = Array(28).fill(0)
      });
      this.huecos.sort((a, b) => 
      {
        var keyA = this.ordenTipos.indexOf(a.tipo);
        var keyB = this.ordenTipos.indexOf(b.tipo);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenHorarios.indexOf(a.horario);
        keyB = this.ordenHorarios.indexOf(b.horario);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        keyA = this.ordenEspacios.indexOf(a.lugar.split('-')[0].slice(0,-1));
        keyB = this.ordenEspacios.indexOf(b.lugar.split('-')[0].slice(0,-1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        keyA = this.ordenSalones.indexOf(a.lugar.split('-')[1].slice(1));
        keyB = this.ordenSalones.indexOf(b.lugar.split('-')[1].slice(1));
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      });
    },
    imprimir(ocultar){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>NECESIDADES FINDE '  + (new Date(this.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase()) + ' - ' + (('0'+new Date(this.date).getDate()).slice(-2)) + ' de ' + new Date(this.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.date).getFullYear() +'</title><style>table {width: 100%; border-collapse: collapse;}; th {vertical-align:top;} th {vertical-align:top !important;border:1px solid black !important} td {vertical-align:top !important;white-space:nomal !important;border:1px solid black !important}</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>NECESIDADES FINDE ' + '</h3><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + (new Date(this.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase()) + ' - ' + (('0'+new Date(this.date).getDate()).slice(-2)) + ' de ' + new Date(this.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.date).getFullYear() + "</h3></div>"

      // SILLAS
      var sillas = v.document.createElement('div')
      sillas.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablasillas = v.document.createElement("table")
      var theadsillas = v.document.createElement("thead")
      theadsillas.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">SILLAS</h1></th></tr>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadsillas.innerHTML += trhead
      var tbodysillas = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'SILLAS').sort((a,b) => {
      var keyA = a.orden;
      var keyB = b.orden;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0
      })
      .forEach((m,i) => {
        if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.stijera + b.stijera, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.stijera * 1) + '</td>';
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.stijera * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.stijera * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.stijera * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.stijera + '</td>' 
            c += espacio.stijera
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodysillas.appendChild(tr)
        }
        if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.scrossback + b.scrossback, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.scrossback * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.scrossback * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.scrossback * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.scrossback * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.scrossback + '</td>' 
            c += espacio.scrossback
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodysillas.appendChild(tr)
        }
        if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.shotel + b.shotel, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.shotel * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.shotel * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.shotel * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.shotel * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.shotel + '</td>' 
            c += espacio.shotel
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodysillas.appendChild(tr)
        }
        if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.splastico + b.splastico, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.splastico * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.splastico * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.splastico * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.splastico * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.splastico + '</td>' 
            c += espacio.splastico
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodysillas.appendChild(tr)
        }
        if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.cojinestijera + b.cojinestijera, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cojinestijera * 1) + '</td>';
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>';
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cojinestijera * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cojinestijera * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cojinestijera * 1)) + '</td>';
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cojinestijera + '</td>' ;
            c += espacio.cojinestijera
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>';
          tbodysillas.appendChild(tr)
        }
        if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.fundassillasplastico + b.fundassillasplastico, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.fundassillasplastico * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fundassillasplastico * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fundassillasplastico * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fundassillasplastico * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.fundassillasplastico + '</td>' 
            c += espacio.fundassillasplastico
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodysillas.appendChild(tr)
        }
      })
      tablasillas.appendChild(theadsillas)
      tablasillas.appendChild(tbodysillas)
      sillas.appendChild(tablasillas)
      if (!ocultar || (ocultar && tbodysillas.childNodes.length > 0)) wrapper.appendChild(sillas)

      // BUFFETS & PLANCHAS
      var buffets = v.document.createElement('div')
      buffets.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablabuffet = v.document.createElement("table");
      var theadbuffet = v.document.createElement("thead");
      theadbuffet.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">BUFFETS & PLANCHAS</h1></th></tr>';
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>';
      });
      trhead += '<th>OBS.</th></tr>'
      theadbuffet.innerHTML += trhead
      var tbodybuffet = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'BUFFETS & PLANCHAS').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.buffetSoporte + b.buffetSoporte, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.buffetSoporte * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.buffetSoporte * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.buffetSoporte * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.buffetSoporte * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.buffetSoporte + '</td>' 
              c += espacio.buffetSoporte
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodybuffet.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.paptvo + b.paptvo, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.paptvo * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.paptvo * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.paptvo * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.paptvo * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.paptvo + '</td>' 
              c += espacio.paptvo
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodybuffet.appendChild(tr)
          }
        })
        tablabuffet.appendChild(theadbuffet)
        tablabuffet.appendChild(tbodybuffet)
        buffets.appendChild(tablabuffet)
        if (!ocultar || (ocultar && tbodybuffet.childNodes.length > 0)) wrapper.appendChild(buffets)
      
      // MESAS
      var mesas = v.document.createElement('div')
      mesas.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablamesas = v.document.createElement("table")
      var theadmesas = v.document.createElement("thead")
      theadmesas.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">MESAS</h1></th>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadmesas.innerHTML += trhead
      var tbodymesas = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'MESAS').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
      })
      .forEach((m,i) => {
        if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.veladoresplegables + b.veladoresplegables, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.veladoresplegables * 1) + '</td>';
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresplegables * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresplegables * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresplegables * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.veladoresplegables + '</td>' 
            c += espacio.veladoresplegables
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.veladoresblancos + b.veladoresblancos, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.veladoresblancos * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresblancos * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresblancos * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.veladoresblancos * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.veladoresblancos + '</td>' 
            c += espacio.veladoresblancos
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.mesas12 + b.mesas12, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mesas12 * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas12 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas12 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas12 * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mesas12 + '</td>' 
            c += espacio.mesas12
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.mesas18 + b.mesas18, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mesas18 * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas18 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas18 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mesas18 * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mesas18 + '</td>' 
            c += espacio.mesas18
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.aspirinasgrandes + b.aspirinasgrandes, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.aspirinasgrandes * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinasgrandes * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinasgrandes * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinasgrandes * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.aspirinasgrandes + '</td>' 
            c += espacio.aspirinasgrandes
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.tableros2m + b.tableros2m, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.tableros2m * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tableros2m * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tableros2m * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tableros2m * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.tableros2m + '</td>' 
            c += espacio.tableros2m
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.tablerospartidos + b.tablerospartidos, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.tablerospartidos * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tablerospartidos * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tablerospartidos * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tablerospartidos * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.tablerospartidos + '</td>' 
            c += espacio.tablerospartidos
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
        if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.aspirinaextraxxl + b.aspirinaextraxxl, 0) == 0)){
          var c = 0;
          var tr = v.document.createElement("tr");
          tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
          tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.aspirinaextraxxl * 1) + '</td>'
          tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
          tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinaextraxxl * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinaextraxxl * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.aspirinaextraxxl * 1)) + '</td>'
          this.espacios.forEach(espacio => {
            tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.aspirinaextraxxl + '</td>' 
            c += espacio.aspirinaextraxxl
          });
          tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
          tbodymesas.appendChild(tr)
        }
      })
      tablamesas.appendChild(theadmesas)
      tablamesas.appendChild(tbodymesas)
      mesas.appendChild(tablamesas)
      if (!ocultar || (ocultar && tbodymesas.childNodes.length > 0)) wrapper.appendChild(mesas)

      // CRISTAL
      var cristal = v.document.createElement('div')
      cristal.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablacristal = v.document.createElement("table")
      var theadcristal = v.document.createElement("thead")
      theadcristal.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">CRISTAL</h1></th></tr>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadcristal.innerHTML += trhead
      var tbodycristal = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'CRISTAL').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.copacava + b.copacava, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copacava * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacava * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacava * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacava * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copacava + '</td>' 
              c += espacio.copacava
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.copavino + b.copavino, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copavino * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavino * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavino * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavino * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copavino + '</td>' 
              c += espacio.copavino
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.copaagualila + b.copaagualila, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copaagualila * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaagualila * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaagualila * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaagualila * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copaagualila + '</td>' 
              c += espacio.copaagualila
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.vasoaguaambar + b.vasoaguaambar, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.vasoaguaambar * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguaambar * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguaambar * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguaambar * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.vasoaguaambar + '</td>' 
              c += espacio.vasoaguaambar
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.vasoaguatrans + b.vasoaguatrans, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.vasoaguatrans * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguatrans * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguatrans * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasoaguatrans * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.vasoaguatrans + '</td>' 
              c += espacio.vasoaguatrans
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.copavinosalon + b.copavinosalon, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copavinosalon * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavinosalon * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavinosalon * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copavinosalon * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copavinosalon + '</td>' 
              c += espacio.copavinosalon
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.copacavasalon + b.copacavasalon, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copacavasalon * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacavasalon * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacavasalon * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copacavasalon * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copacavasalon + '</td>' 
              c += espacio.copacavasalon
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.cbff + b.cbff, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cbff * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cbff * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cbff * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cbff * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cbff + '</td>' 
              c += espacio.cbff
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 8 && !(ocultar && this.espacios.reduce((a, b) => a.copaBalon70 + b.copaBalon70, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copaBalon70 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaBalon70 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaBalon70 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaBalon70 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copaBalon70 + '</td>' 
              c += espacio.copaBalon70
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 9 && !(ocultar && this.espacios.reduce((a, b) => a.copaTubo + b.copaTubo, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.copaTubo * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaTubo * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaTubo * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.copaTubo * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.copaTubo + '</td>' 
              c += espacio.copaTubo
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
          if (i == 10 && !(ocultar && this.espacios.reduce((a, b) => a.vasosidra + b.vasosidra, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.vasosidra * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasosidra * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasosidra * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.vasosidra * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.vasosidra + '</td>' 
              c += espacio.vasosidra
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycristal.appendChild(tr)
          }
        })
        tablacristal.appendChild(theadcristal)
        tablacristal.appendChild(tbodycristal)
        cristal.appendChild(tablacristal)
        if (!ocultar || (ocultar && tbodycristal.childNodes.length > 0)) wrapper.appendChild(cristal)

      // MENAJE
      var menaje = v.document.createElement('div')
      menaje.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablamenaje = v.document.createElement("table")
      var theadmenaje = v.document.createElement("thead")
      theadmenaje.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">MENAJE</h1></th></tr>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadmenaje.innerHTML += trhead
      var tbodymenaje = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'MENAJE').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.bp1 + b.bp1, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bp1 * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp1 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp1 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp1 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bp1 + '</td>' 
              c += espacio.bp1
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.bp2 + b.bp2, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bp2 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp2 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp2 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp2 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bp2 + '</td>' 
              c += espacio.bp2
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.bp3 + b.bp3, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bp3 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp3 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp3 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bp3 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bp3 + '</td>' 
              c += espacio.bp3
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.ppc1 + b.ppc1, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.ppc1 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc1 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc1 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc1 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.ppc1 + '</td>' 
              c += espacio.ppc1
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.ppc2 + b.ppc2, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.ppc2 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc2 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc2 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ppc2 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.ppc2 + '</td>' 
              c += espacio.ppc2
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.bas + b.bas, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bas * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bas * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bas * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bas * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bas + '</td>' 
              c += espacio.bas
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.bls + b.bls, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bls * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bls * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bls * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bls * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bls + '</td>' 
              c += espacio.bls
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.bolNose + b.bolNose, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.bolNose * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bolNose * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bolNose * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.bolNose * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.bolNose + '</td>' 
              c += espacio.bolNose
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 8 && !(ocultar && this.espacios.reduce((a, b) => a.ph + b.ph, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.ph * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ph * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ph * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.ph * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.ph + '</td>' 
              c += espacio.ph
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 9 && !(ocultar && this.espacios.reduce((a, b) => a.plp + b.plp, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.plp * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plp * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plp * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plp * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.plp + '</td>' 
              c += espacio.plp
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 10 && !(ocultar && this.espacios.reduce((a, b) => a.plcv + b.plcv, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.plcv * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plcv * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plcv * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.plcv * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.plcv + '</td>' 
              c += espacio.plcv
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 11 && !(ocultar && this.espacios.reduce((a, b) => a.pla + b.pla, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.pla * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pla * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pla * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pla * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.pla + '</td>' 
              c += espacio.pla
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
          if (i == 12 && !(ocultar && this.espacios.reduce((a, b) => a.pca + b.pca, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.pca * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pca * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pca * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pca * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.pca + '</td>' 
              c += espacio.pca
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodymenaje.appendChild(tr)
          }
        })
        tablamenaje.appendChild(theadmenaje)
        tablamenaje.appendChild(tbodymenaje)
        menaje.appendChild(tablamenaje)
        if (!ocultar || (ocultar && tbodymenaje.childNodes.length > 0)) wrapper.appendChild(menaje)

      // SERVICIO CAFÉ
      var cafe = v.document.createElement('div')
      cafe.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablacafe = v.document.createElement("table")
      var theadcafe = v.document.createElement("thead")
      theadcafe.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">SERVICIO CAFÉ</h1></th>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadcafe.innerHTML += trhead
      var tbodycafe = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'SERVICIO CAFÉ').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.cc + b.cc, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cc * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cc * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cc * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cc * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cc + '</td>' 
              c += espacio.cc
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycafe.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.jc + b.jc, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.jc * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.jc * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.jc * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.jc * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.jc + '</td>' 
              c += espacio.jc
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycafe.appendChild(tr)
          }
        })
        tablacafe.appendChild(theadcafe)
        tablacafe.appendChild(tbodycafe)
        cafe.appendChild(tablacafe)
        if (!ocultar || (ocultar && tbodycafe.childNodes.length > 0)) wrapper.appendChild(cafe)

      // LENCERÍA
      var lenceria = v.document.createElement('div')
      lenceria.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablalenceria = v.document.createElement("table")
      var theadlenceria = v.document.createElement("thead")
      theadlenceria.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">LENCERÍA</h1></th>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadlenceria.innerHTML += trhead
      var tbodylenceria = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'LENCERÍA').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.serv + b.serv, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.serv * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.serv * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.serv * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.serv * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.serv + '</td>' 
              c += espacio.serv
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.mmgrnd + b.mmgrnd, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mmgrnd * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mmgrnd * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mmgrnd * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mmgrnd * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mmgrnd + '</td>' 
              c += espacio.mmgrnd
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.mllnn + b.mllnn, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mllnn * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mllnn * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mllnn * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mllnn * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mllnn + '</td>' 
              c += espacio.mllnn
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.mbrgrnd + b.mbrgrnd, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mbrgrnd * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrgrnd * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrgrnd * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrgrnd * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mbrgrnd + '</td>' 
              c += espacio.mbrgrnd
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.mgrlgrnd + b.mgrlgrnd, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mgrlgrnd * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgrlgrnd * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgrlgrnd * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgrlgrnd * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mgrlgrnd + '</td>' 
              c += espacio.mgrlgrnd
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.mgjspgrnd + b.mgjspgrnd, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mgjspgrnd * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjspgrnd * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjspgrnd * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjspgrnd * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mgjspgrnd + '</td>' 
              c += espacio.mgjspgrnd
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.mcomb + b.mcomb, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mcomb * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcomb * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcomb * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcomb * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mcomb + '</td>' 
              c += espacio.mcomb
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.mbrpq + b.mbrpq, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mbrpq * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrpq * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrpq * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrpq * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mbrpq + '</td>' 
              c += espacio.mbrpq
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 8 && !(ocultar && this.espacios.reduce((a, b) => a.mbrllnn + b.mbrllnn, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mbrllnn * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrllnn * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrllnn * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mbrllnn * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mbrllnn + '</td>' 
              c += espacio.mbrllnn
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 9 && !(ocultar && this.espacios.reduce((a, b) => a.mcallnnp + b.mcallnnp, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mcallnnp * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcallnnp * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcallnnp * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mcallnnp * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mcallnnp + '</td>' 
              c += espacio.mcallnnp
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
          if (i == 10 && !(ocultar && this.espacios.reduce((a, b) => a.mgjsp + b.mgjsp, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.mgjsp * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjsp * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjsp * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.mgjsp * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.mgjsp + '</td>' 
              c += espacio.mgjsp
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodylenceria.appendChild(tr)
          }
        })
        tablalenceria.appendChild(theadlenceria)
        tablalenceria.appendChild(tbodylenceria)
        lenceria.appendChild(tablalenceria)
        if (!ocultar || (ocultar && tbodylenceria.childNodes.length > 0)) wrapper.appendChild(lenceria)
           
      // CUBIERTOS
      var cubiertos = v.document.createElement('div')
      cubiertos.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black;"
      var tablacubiertos = v.document.createElement("table")
      var theadcubiertos = v.document.createElement("thead")
      theadcubiertos.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">CUBIERTOS</h1></th>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadcubiertos.innerHTML += trhead
      var tbodycubiertos = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'CUBIERTOS').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.cuchillos + b.cuchillos, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cuchillos * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cuchillos * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cuchillos * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cuchillos * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cuchillos + '</td>' 
              c += espacio.cuchillos
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.tenedores + b.tenedores, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.tenedores * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tenedores * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tenedores * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tenedores * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.tenedores + '</td>' 
              c += espacio.tenedores
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.cucharas + b.cucharas, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cucharas * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cucharas * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cucharas * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cucharas * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cucharas + '</td>' 
              c += espacio.cucharas
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.tTrinchero + b.tTrinchero, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.tTrinchero * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tTrinchero * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tTrinchero * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tTrinchero * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.tTrinchero + '</td>' 
              c += espacio.tTrinchero
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.tAncho + b.tAncho, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.tAncho * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tAncho * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tAncho * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.tAncho * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.tAncho + '</td>' 
              c += espacio.tAncho
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.cTrinchero + b.cTrinchero, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cTrinchero * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cTrinchero * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cTrinchero * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cTrinchero * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cTrinchero + '</td>' 
              c += espacio.cTrinchero
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.cCarne + b.cCarne, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cCarne * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cCarne * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cCarne * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cCarne * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cCarne + '</td>' 
              c += espacio.cCarne
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.pPescado + b.pPescado, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.pPescado * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pPescado * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pPescado * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.pPescado * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.pPescado + '</td>' 
              c += espacio.pPescado
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
          if (i == 8 && !(ocultar && this.espacios.reduce((a, b) => a.cPiscis + b.cPiscis, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cPiscis * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cPiscis * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cPiscis * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cPiscis * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cPiscis + '</td>' 
              c += espacio.cPiscis
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodycubiertos.appendChild(tr)
          }
        })
        tablacubiertos.appendChild(theadcubiertos)
        tablacubiertos.appendChild(tbodycubiertos)
        cubiertos.appendChild(tablacubiertos)
        if (!ocultar || (ocultar && tbodycubiertos.childNodes.length > 0)) wrapper.appendChild(cubiertos)

      // COMPLEMENTOS GENERALES
      var gnrl = v.document.createElement('div')
      gnrl.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black;"
      var tablagenerales = v.document.createElement("table")
      var theadgenerales = v.document.createElement("thead")
      theadgenerales.innerHTML = '<tr style="background-color: #606060;vertical-align: middle !important;"><th colspan="' + (5 + this.espacios.length) + '"><h1 style="margin-bottom: 0">COMPLEMENTOS GENERALES</h1></th>'
      var trhead = '<tr style="background-color: gray"><th>MATERIAL</th><th>TOTAL NEC.</th><th>INV.</th><th>PTE</th>'
      this.espacios.forEach(esp => {
        trhead += '<th>' + esp.espacio.slice(0,3) + ' (' + esp.nInv + ')</th>'
      });
      trhead += '<th>OBS.</th></tr>'
      theadgenerales.innerHTML += trhead
      var tbodygenerales = v.document.createElement("tbody")
      this.stock.filter(s => s.familia == 'COMPLEMENTOS GENERALES').sort((a,b) => {
        var keyA = a.orden;
        var keyB = b.orden;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
        })
        .forEach((m,i) => {
          if (i == 0 && !(ocultar && this.espacios.reduce((a, b) => a.hornoGas + b.hornoGas, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.hornoGas * 1) + '</td>';
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.hornoGas * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.hornoGas * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.hornoGas * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.hornoGas + '</td>' 
              c += espacio.hornoGas
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 1 && !(ocultar && this.espacios.reduce((a, b) => a.horno380 + b.horno380, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.horno380 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.horno380 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.horno380 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.horno380 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.horno380 + '</td>' 
              c += espacio.horno380
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 2 && !(ocultar && this.espacios.reduce((a, b) => a.cafeteraMolinillo + b.cafeteraMolinillo, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cafeteraMolinillo * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cafeteraMolinillo * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cafeteraMolinillo * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cafeteraMolinillo * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cafeteraMolinillo + '</td>' 
              c += espacio.cafeteraMolinillo
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 3 && !(ocultar && this.espacios.reduce((a, b) => a.arcones + b.arcones, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.arcones * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.arcones * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.arcones * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.arcones * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.arcones + '</td>' 
              c += espacio.arcones
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 4 && !(ocultar && this.espacios.reduce((a, b) => a.fuegoTierra + b.fuegoTierra, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.fuegoTierra * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fuegoTierra * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fuegoTierra * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.fuegoTierra * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.fuegoTierra + '</td>' 
              c += espacio.fuegoTierra
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 5 && !(ocultar && this.espacios.reduce((a, b) => a.alargadores + b.alargadores, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.alargadores * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.alargadores * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.alargadores * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.alargadores * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.alargadores + '</td>' 
              c += espacio.alargadores
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 6 && !(ocultar && this.espacios.reduce((a, b) => a.petaca + b.petaca, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.petaca * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.petaca * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.petaca * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.petaca * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.petaca + '</td>' 
              c += espacio.petaca
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 7 && !(ocultar && this.espacios.reduce((a, b) => a.cable380 + b.cable380, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.cable380 * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cable380 * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cable380 * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.cable380 * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.cable380 + '</td>' 
              c += espacio.cable380
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
          if (i == 8 && !(ocultar && this.espacios.reduce((a, b) => a.focoLuz + b.focoLuz, 0) == 0)){
            var c = 0;
            var tr = v.document.createElement("tr");
            tr.innerHTML += '<td style="font-size: small !important">' + m.nombre + '</td>';
            tr.innerHTML += '<td style="text-align: center;color: #0086b3">' + Math.abs(this.pte.focoLuz * 1) + '</td>'
            tr.innerHTML += '<td style="text-align: center">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + '</td>'
            tr.innerHTML += '<td style="text-align: center;' + (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.focoLuz * 1)) < 0 ? 'color:red' : (((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.focoLuz * 1)) == 0 ? 'color:orange' : '')) + '">' + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) + (this.pte.focoLuz * 1)) + '</td>'
            this.espacios.forEach(espacio => {
              tr.innerHTML += '<td style="text-align: center;color: #0086b3xº">' + espacio.focoLuz + '</td>' 
              c += espacio.focoLuz
            });
            tr.innerHTML += '<td style="color: #0086b3">' + (this.observaciones.filter(obs => obs.idMaterial == m.idMaterial)[0].observaciones || '') + '</td>'
            tbodygenerales.appendChild(tr)
          }
        })
        tablagenerales.appendChild(theadgenerales)
        tablagenerales.appendChild(tbodygenerales)
        gnrl.appendChild(tablagenerales)
        if (!ocultar || (ocultar && tbodygenerales.childNodes.length > 0)) wrapper.appendChild(gnrl)
      v.document.write(wrapper.outerHTML)
      v.print()
      // v.close()
    },
    hasEvents() {
      return this.huecos.length > 0;
    },
    isNotPb() {
      return this.huecos.filter((ev) => ["Boda","Comunión","Evento","Corporativo"].includes(ev.tipo)).length > 0;
    },
    calc() {
      return this.huecos.filter((ev) => (ev.tipo == "Boda" || (((ev.nAdultos || 0) + (ev.nNinos || 0)) > 49))).length > 0;
    },
    isCat() {
      return this.items.some((ev) => ev.nombreEspacio == "CATERING" ).length > 0;
    },
  },
  mounted() {
    this.getHuecos();
    this.getStock();
    this.getObservaciones();
		// Autosave
		// const self = this
		// this.autosave = setInterval( async function () {
		// 	await self.savePlan(true);
		// }, 1000 * 30 )

  },
  async beforeRouteLeave(to, from, next) {
    const confirmation = await this.$refs.confirm.open(
      "Guardar los cambios",
      "¿Quieres guardar los cambios antes de irte?",
      { color: "primary" }
    );
    if (confirmation) {
      await this.savePlan(true);
    }
    next();
  },
	beforeDestroy() {
		// clearInterval( this.autosave )
	}
};
</script>

<style scoped>
.active2{
  max-height: 10000px;
}
.activent2{
  max-height: 55px;
}
</style>